import { ThemeFont } from '@livepolls/ui-components/src/enums/theme-font.enum';
import { ThemeType } from '@livepolls/ui-components/src/enums/theme-type.enum';
import {
  ITheme,
  ThemeSettingFieldDto,
} from '@livepolls/ui-components/src/interfaces/theme.interface';
import { DEFAULT_COVER_IMAGE_URL } from 'src/utils/theme-cover-image.utils';

export const standardThemes = [
  { name: 'Default Blue', button: '#1B87E6', main: '#1B3380' },
  { name: 'Default Red', button: '#F00014', main: '#78000A' },
  { name: 'Default Orange', button: '#FF8C00', main: '#804600' },
  { name: 'Default Green', button: '#3FCA5A', main: '#1D682B' },
  { name: 'Default Light Blue', button: '#02BAE2', main: '#015D71' },
  { name: 'Default Grey', button: '#9B9B9B', main: '#4D4D4D' },
  { name: 'Default Purple', button: '#9131AA', main: '#481855' },
];

export const findCustomizedLivePollTheme = (themes: ITheme[]): ITheme => {
  const customizedLivePllTheme = themes.find(
    theme => theme.type === ThemeType.CUSTOMIZED_LIVEPOLL,
  );

  if (customizedLivePllTheme) {
    return customizedLivePllTheme;
  }
  throw new Error('No Default Theme found');
};

export const findThemeById = (
  selectedThemeId: number,
  themes: ITheme[],
): ITheme | undefined => {
  return themes.find(theme => theme.id === selectedThemeId);
};

export const findThemeIndex = (
  selectedThemeId: number,
  themes: ITheme[],
): number => {
  return themes.findIndex(theme => theme.id === selectedThemeId);
};

export const getStandardThemeSetting = (
  mainColor: string,
  btnColor: string,
) => {
  return {
    logo: '',
    coverImage: DEFAULT_COVER_IMAGE_URL,
    font: ThemeFont.FIRA_SANS,
    color: {
      cover: '',
      main: mainColor,
      button: btnColor,
      title: '#545E6B',
      answer: mainColor,
      sessionName: '#9B9B9B',
    },
  };
};

export const comparePropertiesRecursively = (
  compareTo: any,
  compareFrom: any,
): boolean => {
  if (compareTo === compareFrom) {
    return true;
  }
  if (
    compareTo == null ||
    compareFrom == null ||
    !(compareTo instanceof Object) ||
    !(compareFrom instanceof Object)
  ) {
    return false;
  }

  const compareToKeys = Object.keys(compareTo);
  const compareFromKeys = Object.keys(compareFrom);
  for (const key of compareFromKeys) {
    if (
      !compareToKeys.includes(key) ||
      !comparePropertiesRecursively(compareTo[key], compareFrom[key])
    )
      return false;
  }

  return true;
};

export const isStandardThemeSetting = (
  themeSetting: ThemeSettingFieldDto,
): boolean => {
  const standardTheme = standardThemes.find(
    theme => theme.button === themeSetting.color.button,
  );
  if (!standardTheme) {
    return false;
  }

  const standardThemeSetting = getStandardThemeSetting(
    standardTheme.main,
    standardTheme.button,
  );

  return comparePropertiesRecursively(themeSetting, standardThemeSetting);
};

export const getStandardThemeName = (btnColor: string): string => {
  return standardThemes.find(theme => theme.button === btnColor)!.name;
};

export const getStandardThemeColorByName = (name: string): string | null => {
  const standardTheme = standardThemes.find(theme => theme.name === name);
  if (standardTheme) {
    return standardTheme.button;
  }
  return null;
};

export const getShortFileName = (fileName: string) => {
  if (fileName.length > 8) {
    const extension = fileName.split('.').pop();
    const firstThreeChars = fileName.slice(0, 3);

    const shortName = firstThreeChars + '...' + extension;
    return shortName;
  }
  return fileName;
};
